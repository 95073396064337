import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

import Layout from '../components/layout';
import SEO from '../components/seo';

const Styled404 = styled.div`
	/* 		  nav 6.25  footer 3 */
	height: calc(100vh - 9.25rem);
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	h1 {
		color: ${(p) => p.theme.color.red};
	}
	p {
		margin: 3rem;
	}
	@media all and (min-width: 1600px) {
		/* 		  nav 8.95  footer 6 */
		height: calc(100vh - 14.95rem);
		h1 {
			font-size: 3rem;
			line-height: 3.5rem;
		}
		p,
		a {
			font-size: 1.5rem;
			line-height: 1.8rem;
		}
	}
`;

const NotFoundPage = () => (
	<Layout>
		<Styled404>
			<SEO title="Page not found" />
			<div>
				<h1>The page you are looking for is not found.</h1>
				<p>Looks like the content you're looking for is no longer here.</p>
				<Link to="/" className="redButton">
					BACK TO HOMEPAGE
				</Link>
			</div>
		</Styled404>
	</Layout>
);

export default NotFoundPage;
